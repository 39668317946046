import React from 'react'
import { Link } from 'gatsby'
import { EnsureRole } from '../../components/admin/ensure-role'

export default function AdminPage() {
  return (
    <EnsureRole
      userRole="admin"
      protectedContent={() => (
        <>
          <h2>Welcome to Admin!</h2>
          <p>
            <Link to="/app-admin/upload-price-list">Upload Price List</Link>
          </p>
        </>
      )}
    />
  )
}
